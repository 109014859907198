import { useWindowScroll } from "react-use";

const ScrollToTop = () => {
  const { y } = useWindowScroll();

  const onButtonClick = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <div
      onClick={onButtonClick}
      id="back-to-top"
      className="btn btn-icon btn-primary back-to-top"
      style={{
        display: y > 220 ? "block" : "none",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="prefix__feather prefix__feather-arrow-up"
      >
        <path d="M12 19V5M5 12l7-7 7 7" />
      </svg>
    </div>
  );
};

export default ScrollToTop;
