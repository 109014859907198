/* eslint-disable jsx-a11y/anchor-is-valid */
const Header = ({ setAuth }) => {
  return (
    <header id="topnav" className="defaultscroll sticky">
      <div
        className="container-fluid"
        style={{ background: 'url("images/nav_bar_top.png")' }}
      >
        <div>
          <a className="logo">
            <img src="images/logo.png" height="40" alt="" />
          </a>
        </div>
        <ul className="buy-button list-inline mb-0">
          <li className="list-inline-item mb-0">
            <div className="dropdown dropdown-primary">
              <button
                type="button"
                className="btn btn-icon btn-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="uil uil-user align-middle icons" />
              </button>
              <div
                className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-3"
                style={{ width: "200px" }}
              >
                <div
                  onClick={() => {
                    localStorage.removeItem("user_token");
                    setAuth({ isLogin: false });
                  }}
                  style={{ cursor: "pointer" }}
                  className="dropdown-item text-dark"
                >
                  <i className="uil uil-sign-out-alt align-middle me-1" />{" "}
                  Logout
                </div>
              </div>
            </div>
          </li>
        </ul>
        {/* End Logo container*/}
        <div className="menu-extras">
          <div className="menu-item">
            {/* Mobile menu toggle*/}
            <a className="navbar-toggle" id="isToggle" onclick="toggleMenu()">
              <div className="lines">
                <span />
                <span />
                <span />
              </div>
            </a>
            {/* End mobile menu toggle*/}
          </div>
        </div>
        <div id="navigation">
          <div className="buy-menu-btn d-none">
            <li className="list-inline-item mb-0">
              <div className="dropdown dropdown-outline-primary">
                <button
                  type="button"
                  className="btn btn-icon btn-outline-primary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="uil uil-user align-middle icons" />
                </button>
                <div
                  className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-3"
                  style={{ width: "200px" }}
                >
                  <a className="dropdown-item text-dark" href="#">
                    <i className="uil uil-user align-middle me-1" /> Account
                  </a>
                  <div className="dropdown-divider my-3 border-top" />
                  <a className="dropdown-item text-dark" href="#">
                    <i className="uil uil-sign-out-alt align-middle me-1" />{" "}
                    Logout
                  </a>
                </div>
              </div>
            </li>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
