import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SidebarArea from "../../components/SidebarArea";
import Table from "../../components/Table";
import { useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import qs from "qs";
import { useSetState } from "react-use";
import ScrollToTop from "../../components/ScrollToTop";

const MainScreen = ({ setAuth }) => {
  const [searchObject, setSearchObject] = useState({
    operator_id: "",
    school: "",
    grade: "",
    subject: "",
    class_of_school: "",
    period: 0,
  });
  const [tableState, setTableState] = useSetState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [totalPageSize, setTotalPageSize] = useState(-1);
  const [fetchLoading, setFetchLoading] = useState(false);

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery(["events", searchObject, tableState], async () => {
    const { data } = await axios.get(
      `/events?page_size=${tableState.pageSize}&page_index=${
        tableState.pageIndex
      }&${qs.stringify(searchObject)}`
    );
    setTotalPageSize(data.total_pages);
    return data.data;
  });

  const onSyncButtonClick = () => {
    setFetchLoading(true);
    axios
      .get(
        `/events/fetch_and_show?page_size=${tableState.pageSize}&page_index=${
          tableState.pageIndex
        }&${qs.stringify(searchObject)}`
      )
      .then(() => {
        refetch();
      })
      .finally(() => {
        setFetchLoading(false);
      });
  };

  const gotoPage = (index) => {
    setTableState({ pageIndex: index });
  };

  const nextPage = () => {
    setTableState({ pageIndex: tableState.pageIndex + 1 });
  };

  const previousPage = () => {
    setTableState({ pageIndex: tableState.pageIndex - 1 });
  };

  return (
    <div>
      <Header setAuth={setAuth} />
      <section
        className="bg-half-100 bg-light d-table222 w-100"
        style={{
          background: 'url("images/bg.png") center top',
          backgroundAttachment: "fixed",
        }}
      >
        <div className="container-fluid mt-lg-3 p-3">
          <div className="row">
            <SidebarArea
              setSearchObject={setSearchObject}
              setTableState={setTableState}
              onSyncButtonClick={onSyncButtonClick}
              loading={isLoading || fetchLoading}
            />

            <Table
              data={data}
              tableState={tableState}
              totalPageSize={totalPageSize}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              loading={isLoading || fetchLoading}
            />
          </div>
        </div>
      </section>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default MainScreen;
