import { Roller } from "react-css-spinners";
import styled from "styled-components";

const LoadingScreen = () => {
  return (
    <SplashScreenWrapper>
      <div>
        <img alt="logo" src="images/logo.png" />
        <Roller />
      </div>
    </SplashScreenWrapper>
  );
};
export default LoadingScreen;

const MainWrapperMap = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: grey;
  display: flex;
  position: relative;
`;

const SplashScreenWrapper = styled(MainWrapperMap)`
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      height: 80px;
      margin-bottom: 40px;
    }
  }
`;
