// Dev
// export const baseURL = "http://207.180.230.73/meetings/v1";
// export const fileServer = "http://207.180.230.73/meetings/";

// Prod
//  export const baseURL = "http://20.74.161.160/Monitoring/v1";
//  export const fileServer = "http://20.74.161.160/Monitoring/";

// Dev
export const baseURL = "https://monitoringapp.charterschools.ae/Services/v1";
export const fileServer = "https://monitoringapp.charterschools.ae/Services/";
