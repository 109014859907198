const Footer = () => {
  return (
    <footer className="footer footer-bar">
      <div className="container-fluid text-center  ">
        <div className="row align-items-center">
          <div className="col-sm-6">
            <div className="text-sm-start">
              <p className="mb-0">
                ©{" "}
                <a href="index.html" className="text-reset">
                  Team Monitoring
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
