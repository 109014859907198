import { Controller } from "react-hook-form";
import get from "lodash.get";
import styled from "styled-components";
import { useEffect } from "react";
import { defaultValues } from "../SidebarArea";

const SelectHookForm = ({
  control,
  name,
  defaultValue,
  selectClasses = "",
  placeholder,
  options = [],
  dependents = [],
  setValue = () => null,
  errors = {},
  isSubmitted,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue === 0 ? 0 : defaultValue ? defaultValue : ""}
      render={({ field: { onChange, onBlur, value } }) => (
        <SelectComponent
          {...{
            selectClasses,
            value,
            onBlur,
            onChange,
            placeholder,
            options,
            setValue,
            dependents,
            name,
            errors,
            isSubmitted,
            defaultValue,
          }}
        />
      )}
    />
  );
};

export default SelectHookForm;

const SelectComponent = ({
  selectClasses,
  value,
  onBlur,
  onChange,
  placeholder,
  options,
  setValue,
  dependents,
  name,
  errors,
  isSubmitted,
  defaultValue,
}) => {
  useEffect(() => {
    window.jQuery(`#${name}-select-dropdown`).selectpicker("refresh");
  }, [options, name, value]);

  return (
    <>
      <select
        className={`form-control selectpicker rounded shadow ${selectClasses}`}
        data-size={4}
        data-live-search="true"
        id={`${name}-select-dropdown`}
        value={value}
        onBlur={onBlur}
        onChange={(e) => {
          dependents.forEach((dep) => {
            setValue(dep, defaultValues[dep], { shouldValidate: isSubmitted });
          });
          onChange(e.target.value);
        }}
      >
        <option
          selected
          disabled
          value={defaultValue === 0 ? 0 : defaultValue ? defaultValue : ""}
        >
          {placeholder}
        </option>
        {options.map((opt, index) => (
          <option key={index} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
      {Boolean(get(errors, name)) && (
        <ErrorInput>{get(errors, name)?.message}</ErrorInput>
      )}
    </>
  );
};

const ErrorInput = styled.p`
  display: block !important;
  color: red;
  margin-bottom: -10px;
`;
